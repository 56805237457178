import videojs from 'video.js'
import { InteractionElementComponent } from './InteractionElementComponent'

import './DragNDropComponent.css'
import { normalizeMouseCoord_ } from '../../../utils'
import { IBaseElementOptions } from '../types'

export interface DragNDropComponentOptions extends IBaseElementOptions {

	interaction_id: number,
	interaction_element_type_id: number,
}

export default class DragNDropComponent extends InteractionElementComponent {

	constructor(player, options: DragNDropComponentOptions) {
		super(player, options)

		const DragNDropState = {
			pointerId: null,
			isDragging: false,
			mousePos: { x: 0, y: 0 },
			elDOMRect: null
		}

		this.state = Object.assign(this.state, DragNDropState)

		const el_ = this.el() as HTMLElement

		if (el_) {

			if (options.interaction_element_type_id === 2) {
				const mouseEvents = [
					{
						name: 'mousedown',
						callback: ($ev) => this.handleMouseDown($ev)
					},
					{
						name: 'mouseup',
						callback: () => this.handleMouseUp()
					},
					{
						name: 'touchstart',
						callback: ($ev) => { this.handleMouseDown($ev, true) }
					},
					{
						name: 'touchend',
						callback: () => this.handleMouseUp()
					}
				]

				mouseEvents.forEach(event =>
					this.el().addEventListener(event.name, ($ev) => event.callback($ev)))

				/** Global window event listeners */
				addEventListener('mousemove', ($ev) => this.handleMouseMove($ev))
				addEventListener('touchmove', ($ev) => this.handleMouseMove($ev, true))
			}

		}

	}

	createEl() {
		const { id, background, interaction_element_type_id: type_id } = this.options_ as DragNDropComponentOptions

		// /** Todo: Generate random hash number for element in case id is missing */
		const elementID = id ? id : 'RND_NUM_1'

		const elBkgImgPath = background ?
			`${(<DragNDropComponentOptions>this.options_).API_BASE_URL}/interaction/${background.includes('beleza.png') ? 'transp_bkg.png' : background}` :
			'/static/transp_bkg.png'

		return videojs.dom.createEl('div', {
			id: `act-overlay_dragndrop_el__${elementID}`,
			className: `vjs-act-overlay__el vjs-act-overlay_dragndrop_el ${type_id === 2 ? '__draggable' : '__dropable'}`
		}, {}, [
			type_id === 2 ? null :
				videojs.dom.createEl('div',
					{
						id: `vjs-act-overlay__child_wrapper_${elementID}`,
						className: 'vjs-act-overlay__child_wrapper'
					}),

			videojs.dom.createEl('img',
				{ className: 'vjs-act-overlay__el-img vjs-ect-overlay__el-img_dragndrop' },

				{
					src: elBkgImgPath,
					draggable: false
				})
		])
	}

	/**
	 * Handles mouse and touch movement events.
	 * 
	 * @param {HTMLMouseEvent | HTMLTouchEvent} $event
	 * @param {boolean} isTouch Flag for touch events on mobile devices
	 */
	handleMouseMove($event, isTouch = false) {
		if (this.el() && this.state.isDragging === true) {
			if (isTouch) {
				if ($event.touches.length > 1)
					return $event.preventDefault()
			}

			this.setState({ elDOMRect: this.el().getBoundingClientRect() })

			isTouch ?
				this.setState({ mousePos: { x: $event.touches[0].clientX, y: $event.touches[0].clientY } }) :
				this.setState({ mousePos: { x: $event.x, y: $event.y } })

			/** Check whether mouse is inside true video space */
			const { mousePos } = this.state
			let normalizedCoords = normalizeMouseCoord_({ player: this.player_, mouseX: mousePos.x, mouseY: mousePos.y })

			if (this.state.isDragging === true) {
				const { elDOMRect } = this.state

				Object.assign((<HTMLElement>this.el()).style,
					{
						left: `${normalizedCoords.x - (elDOMRect.width / 2)}px`,
						top: `${normalizedCoords.y - (elDOMRect.height / 2)}px`
					})
			}
		}
	}

	/**
		* Handles initial mouse and touchstart events.
		* 
		* @param {HTMLMouseEvent | HTMLTouchEvent} $event
		* @param {boolean} isTouch Flag for touch events on mobile devices
		* 
		*/
	handleMouseDown($event, isTouch = false) {

		const el_ = this.el() as HTMLElement

		let parent = el_.offsetParent
		if (parent.id.includes('vjs-act-overlay__child_wrapper')) {
			Object.assign(el_.style, { 'position': 'absolute' })

			let interactionBaseLayer = document.getElementById(`vjs-act-intrc-overlay_${(<DragNDropComponentOptions>this.options_).interaction_id}`)

			interactionBaseLayer.appendChild(this.el())

			/** Used only to trigger animations and elements distributions in the main file. Does not follow other events conventions. */
			this.player_.el().dispatchEvent(new CustomEvent('handleclass', { detail: { dzone: parent.id, selected: this.el(), op: 'remove' } }))
		}

		if (isTouch) {
			// $event.preventDefault() // cannot prevent a passive event
		}

		if (this.state.isDragging)
			return this.setState({ isDragging: false })

		this.setState({ isDragging: true })
	}

	handleMouseUp() {
		if (this.state.isDragging) {
			this.player_.el().dispatchEvent(
				new CustomEvent('dragstop',
					{
						detail: {
							elementId: this.options_.id,
							elementDOMRect: this.state.elDOMRect,
							pointerId: (<DragNDropComponentOptions>this.options_).pair_element_id
						}
					})
			)
		}
		this.setState({ isDragging: false })
	}
}

videojs.registerComponent('DragNDropComponent', DragNDropComponent)