import { render, staticRenderFns } from "./CreditHistory.vue?vue&type=template&id=21ec1f59&scoped=true&"
import script from "./CreditHistory.vue?vue&type=script&lang=js&"
export * from "./CreditHistory.vue?vue&type=script&lang=js&"
import style0 from "./CreditHistory.vue?vue&type=style&index=0&id=21ec1f59&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21ec1f59",
  null
  
)

export default component.exports