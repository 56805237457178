import videojs, { VideoJsPlayer } from 'video.js'
import { InteractionElementComponent } from './InteractionElementComponent'
import { IBaseElementOptions } from '../types'


import './MultipleChoiceComponent.css'

export interface MultipleChoiceComponentOptions extends IBaseElementOptions {

	selectionType: string,
	hasMultiple: boolean,
}

export default class MultipleChoiceComponent extends InteractionElementComponent {

	constructor(player: VideoJsPlayer, options: MultipleChoiceComponentOptions) {

		super(player, options)

		this.state = Object.assign(this.state, { pointerId: null, isSelected: null })

		const el_ = this.el() as HTMLElement

		if (el_) {

			this.setState({ pointerId: options.segment_reference_id })

			// Todo: Handle exception when segment_reference_id === null

			videojs.on(el_, 'click', () => {

				this.state.isSelected ? this.toggleCheck(false) : this.toggleCheck(true)

				if (this.state.isSelected === true) {

					return this.player_.el().dispatchEvent(new CustomEvent('choiceselected', { detail: { id: this.options_.id } }))
				}
				return this.player_.el().dispatchEvent(new CustomEvent('choiceunselected', { detail: { id: this.options_.id } }))
			})

			videojs.on(player.el(), 'unselectall', () => this.setState({ isSelected: false }))

			videojs.on(player.el(), 'resetstate', () => this.toggleCheck(false))
		}
	}

	createEl() {

		const { id, background, selectionType } = this.options_ as MultipleChoiceComponentOptions


		/** Todo: Generate random hash number for element in case id is missing */
		const elementID = id ? id : 'RND_NUM_1'

		const elBkgImgPath = background ?
			`${(<MultipleChoiceComponentOptions>this.options_).API_BASE_URL}/interaction/${background.includes('beleza.png') ? 'transp_bkg.png' : background}` :
			'/static/transp_bkg.png'

		const content = [
			(
				selectionType === 'icon' ?
					videojs.dom.createEl(
						'img',
						{ id: `vjs-el__${elementID}_check`, className: 'vjs-el__checked' },
						{ src: `${process.env.VUE_APP_URL}/static/int-check-icon.png` }) :
					''),

			videojs.dom.createEl('img',
				{ className: 'vjs-act-overlay__el-img vjs-ect-overlay__el-img_choices' },
				{ src: elBkgImgPath })
		]

		return videojs.dom.createEl('div', {

			id: `act-overlay_el_${elementID}`,
			className: 'vjs-act-overlay__el'
		}, {}, content)
	}

	/** Wrapper function to call different selected indicators */
	toggleCheck(value = true) {
		const { hasMultiple, selectionType } = this.options_ as MultipleChoiceComponentOptions

		if (!hasMultiple) {
			this.player_.el().dispatchEvent(new CustomEvent('unselectall', {}))
		}

		this.setState({ isSelected: value })

		selectionType === 'icon' ?
			this.toggleCheckIcon() : this.toggleCheckOutline()
	}

	toggleCheckIcon() {
		/** Toggles visiblity `off` of all check buttons */
		if (!(<MultipleChoiceComponentOptions>this.options_).hasMultiple) {
			document.querySelectorAll<HTMLElement>('.vjs-el__checked')
				.forEach(child => Object.assign(child.style, { opacity: 0 }))
		}

		const { id } = this.options_

		let elCheckIcon = document.getElementById(`vjs-el__${id}_check`)

		/** Toggles visiblity `on` of selected element check button */
		if (elCheckIcon) {
			this.state.isSelected ?
				Object.assign(elCheckIcon.style, { opacity: 1 }) :
				Object.assign(elCheckIcon.style, { opacity: 0 })
		}
	}

	toggleCheckOutline() {
		/** Toggles visiblity `off` of all check buttons */
		if (!(<MultipleChoiceComponentOptions>this.options_).hasMultiple) {
			document.querySelectorAll('.vjs-act-overlay__el')
				.forEach(child => {

					// Object.assign(child.style, { border: 'none' })
					if (child.classList.contains('vjs-el__checked-outline'))
						child.classList.remove('vjs-el__checked-outline')
				})
		}

		const { id } = this.options_

		/** Grab the actual element instead of check sibling */
		let elCheckIcon = document.getElementById(`act-overlay_el_${id}`)

		/** Toggles visiblity `on` of selected element check button */
		if (elCheckIcon) {
			this.state.isSelected ?
				elCheckIcon.classList.add('vjs-el__checked-outline') :
				elCheckIcon.classList.remove('vjs-el__checked-outline')
		}
	}
}

videojs.registerComponent('MultipleChoiceComponent', MultipleChoiceComponent)