<template>
  <div id="act-buffer-handler" v-if="playlist">

    <template v-if="Object.keys(segmentGraph).length > 0">

      <Buffer
      v-for="(segment, segmentIndex) in segmentNodes_" :key="segmentIndex"
      :ref="`buffer-${segment.id}`" v-cloak
      :ComponentId="segment.id"
      :segment="segment"
			:src="{ src: segment.value.file?.link??'', type: segment.value.file?.quality === 'hls' ? 'application/x-mpegURL' : 'video/mp4' }"
      :type="segment.value.type"
      :interaction="segment.value.interaction"
      :backdrop="segment.value.background !== null ? segment.value.background : '#EEEEEE'"
      :config="defaultConfig"

      :logo="logo"

      @play.once="handlePlay($event)"
      @submit="handleSubmit($event)"
      @ended="swapBuffer($event)"
      @macroplayback="handleMacroPlayback()"
      />
    </template>

  </div>
</template>

<script>
import Buffer from './Buffer.vue'
import Graph from '../../classes/Graph'
import ImageUtils from '../../classes/ImageUtils'

import videojs from 'video.js'
/**
 * Runtime erros have happened while using videojs cdn, so the packages were installed as heavier but more consistent measure
 * @see https://github.com/videojs/themes
 * */
import 'video.js/dist/video-js.css'
import '@videojs/themes/dist/city/index.css'

import './lang/pt-BR'

import axios from 'axios'
import { ActionAPI } from '../../services/ActionAPI'
import UrlUtils from '../../classes/UrlUtils'
import { nextTick } from 'vue'

// Todo: Add Graph class to manage buffer graph order

export default {

	name: 'BufferHandler',
	components: { Buffer },

	data()
	{
		return {
			logo: null,
			segmentGraph: {},
			colorTheme: {},
			defaultConfig: {
				fluid: false,
				controls: true,
				src: { link: null, type: null },
				preload: 'auto',
				techOrder: ['html5'],
				html5: { vhs: { overrideNative: true } },
				overrideNative: true
			}
		}
	},

	computed: {

		computedSegments_() { return this.playlist.segments },

		computedMeta_(){ return this.playlist.meta },

		segmentNodes_() { return this.segmentGraph.getNodes() },
		
		currentNode_() {
			return this.segmentGraph.nodes[this.segmentGraph.currentNodeIndex] },

		canTrackVisualization_()
		{
			return !this.isPreviwingVideo_
		},

		canTrackInteractionData_()
		{
			return !this.isPreviwingVideo_ && this.isQueryTracking_
		},

		isPreviwingVideo_()
		{
			return this.$route.path.includes('preview')
		},

		isQueryTracking_()
		{
			return Object.keys(this.$route.query).length > 0
		}
	},

	props:{
		playlist: {
			type: Object,
			required: true
		},

		meta: {
			type: Object,
			required: false,
			default: () => { return {} }
		}
	},

	async mounted()
	{
		if('logo' in this.$route.query)
		{
			const image = new ImageUtils(this.$route.query.logo)

			await image.setBackgroundBasedOnForegroundColor()

			this.logo = await image.get()
		}

		this.segmentGraph = this.mountPlaylistManifestGraph()

		/** Prevents runtime error while template is initializing buffer component */
		await nextTick()

		/** Gets div by buffer id since videojs adds a wrapper container on video element */
		let iNodeEl = document.querySelector(`div#buffer-${this.segmentGraph.getCurrentNode().id}`)

		Object.assign(iNodeEl.style, { 'z-index': 99 })

		/** Supported player handlers from consumers */
		window.addEventListener('message', this.messageEventHandler, false)
	},

	beforeDestroy()
	{
		window.removeEventListener('message', this.messageEventHandler)
	},

	methods:{

		messageEventHandler (_message)
		{
			// SUPPORTED_PLAYER_HANDLERS
			const currentNode = this.segmentGraph.getCurrentNode()
			const currentBuffer = this.$refs[`buffer-${currentNode.id}`][0]

			// TODO: parse message origin

			/** Indicates its a supported event source */
			if(!(_message.data.ev) || !currentBuffer)
			{
				return false
			}

			const player = currentBuffer.instance

			const { ev, payload } = _message.data

			switch(ev)
			{
				case 'play':
					player.play()
					break

				case 'pause':
					player.pause()
					break

				case 'setCurrentTime':
					// When called with a payload, currentTime act as a setter
					player.currentTime(payload) 
					break

				case 'getCurrentTime':
					window.parent.postMessage({ ev: 'ACT_getCurrentTime', payload: player.currentTime() }, { 'targetOrigin': _message.origin })
					break
			}
		},

		handlePlay()
		{
			this.countViews()
		},

		async countViews()
		{
			/** Check for cookie existance */
			const { videoId } = this.meta

			if(this.isCookieSet({ name: videoId }) || !this.canTrackVisualization_)
			{
				return
			}

			/** Send request to count the video visualization */
			this.setViewCookie({ name: videoId, expirationTimeInMinutes: 10 })

			const URL = new UrlUtils(window.location)

			/** Does not need or expect action to finish */
			ActionAPI.PlaybackService.countPlayerVisualization(videoId,
				{
					headers: {
						'origin-domain': URL.asParsedObject().origin
					}
				})
		},

		setViewCookie({ name, expirationTimeInMinutes = 5 })
		{
			const now = Date.now()

			const expirationDate = new Date(now + expirationTimeInMinutes * 60000)

			/** check for videoId in meta */
			document.cookie = `${name}=${ new Date(now).toDateString()}; SameSite=None; Secure; expires=${expirationDate.toUTCString()}`
		},

		isCookieSet({ name })
		{
			const cookies = document.cookie

			return cookies.includes(name)
		},

		mountPlaylistManifestGraph()
		{
			let g = new Graph()		

			for (let i = 0; i < this.computedSegments_.length; i++)
			{
				let seg = this.computedSegments_[i]

				g.addNode(seg.id, { ... seg, order: (1 + i) })
			}

			for (let i = 0; i < g.nodes.length; i++) {

        let node = g.nodes[i]
        if(node.value.next_segment){
          let edges = Object.keys(node.value.next_segment)

          for (let j = 0; j < edges.length; j++) {
            let nodeEdge = node.value.next_segment[edges[j]]

            if(nodeEdge !== null){
              g.addEdge(node.id, nodeEdge)
            }

          }
        }
			}

			let starterNodeIndex = this.computedSegments_.findIndex(node => node.id === this.computedMeta_.starterNodeId ? this.computedMeta_.starterNodeId : 0 )

			g.setCurrentNodeIndex(starterNodeIndex)

			return g
		},

		/**  Since it’s the first time we’re calling the `next()` method on the iterable, it returns the first element in the sequence. */
		handleMacroPlayback()
		{
			const firstNodeId = this.getFirstNode()

			this.SET_FRONTBUFFER(firstNodeId)
		},

		getFirstNode()
		{
			return this.segmentGraph.getGraph().entries().next().value[0] // node = [ id, [...edges] ]
		},

		SET_FRONTBUFFER(nodeId){

			/** @todo Add validation for out of bounds assigment on graph */

			this.segmentGraph.setCurrentNodeIndex(this.segmentGraph.getNodeIndex(nodeId)) 

			const currentBufferWrapper = document.querySelector(`div#buffer-${nodeId}`)

			this._moveBufferForward(currentBufferWrapper)
        
			const currentBuffer = this.$refs[`buffer-${nodeId}`][0]

			currentBuffer.instance.play()

			/** Fix content loading on slow connections */
			videojs.trigger(currentBuffer.instance.el_, 'playerresize')
		},

		/** Check graph for node edges then increment current node or optional pointer */
		swapBuffer(/** event_,  */ pointer = null) // void
		{
			/** Check current node edges */
			const edges = this.segmentGraph.getGraph().get(this.segmentGraph.getCurrentNode().id)

			const currentNode = this.segmentGraph.getCurrentNode()
			const currentBufferWrapper = document.querySelector(`div#buffer-${this.segmentGraph.getCurrentNode().id}`)
			const currentBuffer = this.$refs[`buffer-${currentNode.id}`][0]

			this._resetBufferPlaybackState(currentBuffer.instance)

			this._moveBufferToTheBack(currentBufferWrapper)

			let nextNode = edges[0]

			if(!nextNode)
			{
				/** Hides in the console background for production logging */
				return window.parent.postMessage('playbackended', { targetOrigin: '*' })
			}

			if(pointer !== null && edges.length > 1)
			{
				if(edges.some(e => e === pointer))
					nextNode = pointer
			}

			this.SET_FRONTBUFFER(nextNode)
		},

		_resetBufferPlaybackState(bufferInstance)
		{
			bufferInstance.pause()
			bufferInstance.currentTime(0)
		},

		/** Receives the div element wrapping the videojs component */
		_moveBufferForward(bufferWrapper)
		{
			Object.assign(bufferWrapper.style, { zIndex: 99 })
		},

		/** Receives the div element wrapping the videojs component */
		_moveBufferToTheBack(bufferWrapper)
		{
			Object.assign(bufferWrapper.style, { zIndex: 1 })
		},

		computedBufferStyle(segment)
		{
			return {
				'z-index': `${ this.currentNode_.id ===  segment.id ? 50 : 1 }`
			}
		},

		async handleSubmit(event_)
		{
			if('detail' in event_ && typeof event_.detail === 'object')
			{
				if('pointerId' in event_.detail)
				{
					const { detail: { pointerId, interaction_id, is_control_event, outputs }} = event_

					if(this.canTrackInteractionData_ && !is_control_event)
					{
						// Only validates the correct output. The wrong outputs is deprecated
						const answer = Object.keys(outputs).find(k => outputs[k] === pointerId) === 'right' ? true : false
					
						/** Send request to backend */
						await axios.post(
							`${process.env.VUE_APP_API_BASE_URL}/api/videos/${this.$route.params.videoId}/`,
							{
								interaction_id: interaction_id,
								answer: answer
							},
							{
								params: this.$route.query,
								data: {
									interaction_id: interaction_id,
									answer: answer
								},
								headers: {
									'Accept': 'application/json',
									'Content-Type': 'application/json'
								} 
							}
						)
							.catch(e => console.warn(e))
					}

					this.swapBuffer(pointerId)
				}
			}
		}
	}
}
</script>

<style lang="scss" scoped>

.act-buffer-handler{
  width: 100%; height: 100%;
}
</style>
