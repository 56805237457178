<template>

  <video :id="`buffer-${ComponentId}`"
  :class="['video-js', 'vjs-big-play-centered', 'v-buffer']"
  playsinline
  @dblclick.prevent.stop="handleFullscreen()"

/>
  
</template>

<script>
import videojs from 'video.js'
import BufferMixin from './BufferMixin.vue'
import 'videojs-contrib-quality-levels'
import 'videojs-hls-quality-selector'

import 'videojs-logo'
import 'videojs-logo/dist/videojs-logo.css'

import './plugins/interactionPlugin/v2/index'

export default {

	name: 'PlayerBuffer',
	extends: BufferMixin,

	data(){
		return {
			instance: null,
			defaultConfig: {
				resizeManager: true,
				fluid: true,
				controls: false,
				src: { link: null, type: null },
				preload: 'auto',
				techOrder: ['html5'],
				html5: { vhs: { overrideNative: true } },
				overrideNative: true
			}
		}
	},

	computed: {

		elementID_(){ return `buffer-${this.ComponentId}` }
	},

	props: {

		ComponentId: {
			type: Number,
			required: true
		},

		segment : {
      required: true
		},


		src: {
			type: Object,
			required: true
		},

		type: {
			type: String,
			required: true,
			validador: (value) => {
				return ['content', 'interactive'].includes(value)
			}
		},

		logo: {
			type: String,
			required: false,
			default: () => { return null }
		},

		backdrop: {
			type: String,
			required: false,
			default: () => '#EEEEEE'
		},

		interaction: {
			type: Object||null,
			required: false,
			default: () => { return null }
		},

		config: {
			type: Object,
			required: false,
			default: () => this.defaultConfig
		},

		playerAttrs: {
			type: Array,
			required: false,
			default: () => { return [
				{
					'webkit-playsinline': 'webkit-playsinline',
					'playsinline': 'playsinline'
				}
			]}
		}
	},

	mounted()
	{
		let el_ = document.getElementById(this.elementID_)

		if(el_)
		{
      const hasFullUrl = this.segment.value.source.url.includes('https://')

      let panda_hls_src = hasFullUrl ?
                          this.segment.value.source.url :
                          `https://b-vz-4cab7bf9-47f.tv.pandavideo.com.br/${this.segment.value.source.url}/playlist.m3u8`
        console.log('panda_hls_src',panda_hls_src)

			this.instance = videojs(this.elementID_, this.config)

			this.instance.src(panda_hls_src)

			this._setPlayerAttributes(this.instance, new Map(this.playerAttrs))

			this._setBufferColorTheme(this.instance, this.backdrop)

			this._setReplayButtonToLoopEntirePlaybackSequence(this.instance)

			this._handleFullscreenSupport(this.instance)

			this._addProxyEventEmitters(this.instance, this.componentId)

			this._registerPlugins(this.instance, [
				{
					name: 'qualityLevels',
					options: {}
				},
				{
					name: 'hlsQualitySelector',
					options: { displayCurrentQuality: true }
				}
			])

			this.instance.logo(
				{
					image: this.logo,
					fadeDelay: null,
					width: 84,
					padding: 8,
					position: 'bottom-right'
				})

			const controlsTimeoutDelay = 10000

			/** Misc and business specific settings */
			if(this.type === 'content')
			{
				this._handleDynamicControlsDisplay(this.instance, controlsTimeoutDelay)
			}

			if(this.type === 'interactive')
			{
				this.instance.controls(false)
				this.instance.loop(true)

				/**
         * @todo Refactor interaction plugin interfaces and event handling as implementation
         *       is starting to leak out across multiple vue components. See proxy event below */
				this.instance.ready(() => {

					this.instance.InteractionPlugin({... this.interaction })
				})

				/** type_id 4 is Control event */
				this.instance.on('proxysubmit', (event_) => this.$emit('submit',
					{ ... event_,
						detail :{
							... event_.detail,
							'is_control_event': this.interaction.interaction_type_id === 4,
							'interaction_id': this.interaction.id,
							'outputs': this.interaction.output
						}
					}))
			}

			/** Resets to all buffers controls */
			// setTimeout(() => this.instance.controls(false), 3000)
		}
	},

	beforeDestroy()
	{
		this.instance.dispose()
	},

	methods: {

		/**
     * @todo Check how videojs checks for the control bar state internally
     * to enable manual control to be more consistent
     */
		_handleDynamicControlsDisplay(player)
		{
			videojs.on(player.el_, ['mouseenter'], () => {

				if(!player.controls())
				{
					player.controls(true)
				}
			})

			videojs.on(player.el_, ['mouseleave'], () => player.controls(false))

			/** Simulates both mouse events into single action on touch devices */
			let timeoutPointer = null
			videojs.on(player.el_, ['touchstart'], () => {

				if(timeoutPointer !== null)
				{
					clearTimeout(timeoutPointer)
				}

				player.controls(true)
			})
		}
	}
}
</script>

<style lang="scss">
@import '../../styles/scss/mixins.scss';

div.v-buffer
{
  @include fit-container;
  position: absolute;

  &element, video
  {
    top: 0; left: 0;
    height: 100%;
  }

  --control-bar-transition-time: 4.5s;
  .vjs-controls
  {
    animation-delay: 1s;
    transition: all ease-in-out var(--control-bar-transition-time);
  }

  .vjs-control-bar
  {
    transition: all ease-in-out var(--control-bar-transition-time);
  }

  .vjs-user-inactive
  {
    transition: all ease-in-out var(--control-bar-transition-time)
  }

  .vjs-control-bar
  {
    z-index: 99 !important;
  }

  & > div.vjs-logo-content
  {
    /** Prevents clipping with control bar */
    pointer-events: none;
    z-index: 1 !important;
    // background: pink;
  }
}

</style>
