<template>
  <div id="interaction-multiple-choice" class="a-interaction">

    <!-- Workspace environment view -->
    <div class="interaction-multiple-choice">
      <VueDraggableResizable :id="`element-${element.id}`"
      v-for="element in elements" :key="element.id" 
      :title="element.label"
      :class="['a-interaction-element']"
			:data-selection-type="selectionType"
      :style="`
				${
					selectionType === 'outline ' &&
					selectedElement.id === element.id ? 'border: 2px dashed purple;' : ''}
			`"
      :parent="true"
      :resizable="true"
      :draggable="true"

			:lockAspectRatio="true"

			:w="parseInt(element.properties.transform.width)"
			:h="parseInt(element.properties.transform.height)"
      :x="parseInt(element.properties.transform.x)"
      :y="parseInt(element.properties.transform.y)"

      @mouseenter.native="$emit('elementmouseenter', element)"
      @mouseleave.native="$emit('elementmouseleave', element)"

      @click.native.prevent="selectElement(element)"
      @click.right.native.prevent="selectElement(element)"
      @dragging="$emit('elementdragging', element)"
			@dragstop="$emit('element-drag-stop', element)"
      @resizing="$emit('elementresizing', element)"
			@resizestop="$emit('element-resize-stop', element)"
			>

			<img
					v-if="selectionType == 'icon'"
					class="interaction-element__checkmark"
					src="../../assets/interactions/int-check-icon.png"
			>

			<img
				class="interaction-element-icon"
				:src="`${$ImgLink}/interaction/${element.properties.style.backgroundImg}`"
				alt="">

      </VueDraggableResizable>
    </div>
    <!-- Este container e botão de enviar resposta acaba sendo genérico em várias interações,
    possivelmente seria melhor integrá-lo no componente pai. Analisar possíveis fallbacks. -->
    <div class="a-submit-container" v-if="userPlayback && selectedElement">
      <button class="a-submit-btn"
      @click.prevent="submitInteraction(selectedElement)" >
        Confirmar Resposta
      </button>
    </div>
  </div>
</template>

<script>
import VueDraggableResizable from 'vue-draggable-resizable'
import ElementMixin from './ElementMixin.vue'

export default {
	name: 'InteractionMultipleChoice',
	mixins: [ElementMixin],
	components: { VueDraggableResizable },
	props: {
		userPlayback: {
			type: Boolean,
			required: false,
			default: false
		},

		selectionType: {
			type: String,
			required: false,
			default: 'Icon'
		}
	}
}
</script>

<style lang="scss">

.interaction-multiple-choice
{
  @include fit-container;

  position: relative;
  z-index: 2000;
  background: transparent;
  pointer-events: none;

  .a-interaction-element
  {
		@include fit-container;

    position: absolute;
    z-index: auto;
    pointer-events: all;

		display: grid;
		grid-template-columns: minmax(0, max-content) auto;
		grid-template-rows: 100%;
		align-items: center;

		.interaction-element__checkmark
		{
			grid-column: 1/1;
		}

		.interaction-element-icon
		{
			grid-column: 2/2;
			pointer-events: none;
			z-index: 1;
			overflow: hidden;
		}

		img
		{
      @include fit-container;
      @include hover-pointer;
		}
  }
}

@media screen and (max-width: 1020px)
{
  .element-user-view{
    // background: red;
    height: 37% !important;
  }
}
</style>
