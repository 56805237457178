<template>
  <div id="workspace" class="workspace" >

    <div class="workspace-panel">
      <div class="toolbar__top">
        <!-- Toolbar buttons -->
        <div data-container-pos="start">
          <router-link :to="`/workspace/${$route.params.projectId}`">
            <ActionButton class="workspace-btn workspace-tab" title="Tela de edição da ordem de reprodução"
            :disabled="$store.getters['accountStore/isMaster']"
            :style="isFluxogramView ? 'background: #292D3E !important;' : ''">
              <template #content>
                Fluxograma
              </template>
            </ActionButton>
          </router-link>

          <template v-if="$store.getters['dashboardStore/currentProjectType'] === 2">
            <router-link :to="hasInteraction ? `/workspace/${$route.params.projectId}/interaction` : ''">
              <ActionButton
              :class="['workspace-btn workspace-tab']"
              :title="hasInteraction ? 'Tela de edição da interação do vídeo' : 'Necessário criar um componente para configuração interação'"
              :style="`${
                !isFluxogramView ? 'background: #292D3E !important' : ''};
                ${!hasInteraction || !this.$store.getters['globalIsProjectEditable'] ? 'cursor: no-drop !important;' : '' }`"
              >
                <template #content>
                  Interação
                </template>
              </ActionButton>
            </router-link>
          </template>
        </div>

        <div data-container-pos="center"/>

        <div data-container-pos="end">
          <button
          id="save-data-panel-btn"
          class="workspace-btn"
          @click.prevent="saveData()"
          :disabled="uiState.isLoading || !uiState.canSaveChanges || $store.getters['accountStore/isMaster']">
          {{ uiState.isLoading ? 'Salvando' : 'Salvar'}}
          </button>

          <div class="embed-btn-wrapper">
            <button class="workspace-btn"
            @click.prevent="displayEmbedCode()"
            :disabled="true"
            >
            <!-- :disabled="uiState.isLoading" -->
            Incorporar
            </button>
            <div id="embed-code-box" class="embed-code-box" v-if="isEmbbedBoxVisible">
              <input type="text" readonly :value="embedCode">
            </div>
          </div>


          <button class="workspace-btn"
          @click.prevent="watchPreview()"
          :disabled="uiState.isLoading">
          Assistir
          </button>
        </div>

      </div>
    </div>

    <div ref="workspaceScreen" id="draggableWorkspaceScreen"
    :class="[
      'workspace-screen',
      {
        'fluxogram-view-cursor' : isFluxogramView === true,
        'editing__not-allowed' : !this.$store.getters['globalIsProjectEditable']
      }]"
      @click="onWorkspaceClick($event)">

      <router-view v-cloak class="workspace-screen__inner" ref="currentWorkspaceView"/>

      <div id="workspace-background" class="workspace-background"
      @contextmenu.prevent/>

    </div>

    <div class="workspace-bottom-bar">
      
      <div class="__bottom-bar_item">
        <small>Projeto atual: {{ this.currentProject ? this.currentProject.name : 'Error' }}</small>
      </div>

      <!-- Context messages -->
      <!-- @todo Change context messages to allows stacking -->
      <span class="__bottom-bar_item">
        <!-- <template v-if="
        !this.$store.getters['dashboardStore/isProjectEditable'] && !this.$store.getters['accountStore/isMaster']">
          <span 
            class="warning__blocked_editing"
          >
            ⚠ Não é possível editar projetos com status:
          </span>
          <span data-highlighted_status="`${this.$store.getters['dashboardStore/currentProjectStatus'].computedName}`">
            {{this.$store.getters['dashboardStore/currentProjectStatus'].computedName }}
          </span>
        </template> -->

        <template v-if="this.$store.getters['accountStore/isMaster']">
          <span 
            class="warning__blocked_editing"
          >
            ⚠ Não é possível editar projetos como usuário
          </span>
          <span :class="`highlighted_status__Master`">
            Master
          </span>
        </template>
      </span>

      <div class="__bottom-bar_item">
        <small v-if="uiState.canSaveChanges">⭕  Não salvo</small>
        <small v-else-if="!uiState.canSaveChanges"><span style="color: green">✔</span>Salvo</small>
      </div>

      <span class="__bottom-bar_item"></span>

    </div>
  </div>
</template>

<script>
// Component imports
import { mapState } from 'vuex'
import ActionButton from '../../components/ActionButton.vue'

export default {
	name: 'WorkspaceView',
	components: { ActionButton },
	data(){
		return{
			isEmbbedBoxVisible: false,
			embedCode: '',
			isFluxogramView: false,
			localMousePos: {
				currentScrollX: 0, currentScrollY: 0,
				currentMouseX: 0, currentMouseY: 0
			},
			isDraggingFluxogramView: false
		}
	},

	computed: {
		...mapState({
			currentProject: state => state.dashboardStore.currentProject,

			components: state => state.workspaceStore.components,
			componentLines: state => state.workspaceStore.componentLines,
			interactionElements: state => state.workspaceStore.interactionElements,

			dimensionScale: state => state.workspaceStore.dimensionScale,

			uiState: state => state.workspaceStore.uiState,
			mouseState: state => state.ioStore.mouseState
		}),

		hasInteraction(){

			return this.components.some(comp => comp.type === 'interactive')
		}
	},

	async created(){
		this.$store.dispatch('workspaceStore/toggleLoadingState', false) // quickfix

		/** @see https://stackoverflow.com/questions/60524184/redirect-to-404-page-without-modifying-the-url-in-vue-js */
		// Todo: If uri hash doesnt match with user resources,  display 404 page overlay
		this.initialize()
	},

	beforeMount(){},

	mounted() {

		this.addControlEventListenters()

		this.workspaceScreenEl = document.getElementById('workspace-screen')

		this.$store.dispatch('workspaceStore/toggleModalIsOpen', false)
	},

	methods: {
		//#region Setup
		addControlEventListenters(){
			addEventListener('mousedown', this.onMouseClick, true)
			// addEventListener('mousemove', this.onMouseMove, true)
			addEventListener('mouseup', () => {

				this.isDraggingFluxogramView = false

				removeEventListener('click', this.handleScreenDrag)
			})
		},

		async initialize()
		{
			try {
				if(!this.$store.getters['dashboardStore/hasCurrentProject'])
				{
					if(this.$route.params?.projectId)
					{
						await this.$store.dispatch('dashboardStore/mergeUpdateCurrentProject',
              {
                projectId: this.$route.params.projectId
              }
            )
					}

					// throw error
				}

				await this.$store.dispatch('workspaceStore/getProjectSegments', { projectId: this.$route.params.projectId })

				if(!this.$store.getters['globalIsProjectEditable'])
				{
					// Display warning message and prevent from interaction with project.
				}

				addEventListener('mousemove', this.handleScreenDrag, true)

				// confirm('Um erro ocorreu ao recuperar os dados deste projeto. Entre em contato com o suporte.')
				// return this.$router.push('/dashboard')
			}
			catch (error) {

				console.error(error)
				confirm('Um erro ocorreu ao recuperar os dados deste projeto. Entre em contato com o suporte.')
				return this.$router.push('/dashboard')
			}
			
		},

		onMouseClick(event_)
		{
			/** Allows screen drag only on fluxogram view */
			const currentView = this.$refs.currentWorkspaceView

			/** Prevents from dragging screen through a component */
			if(this.$refs.workspaceScreen && currentView.$el.id === 'fluxogram-view' && !event_.composedPath().some(el => 'id' in el && el.id.includes('a-component')))
			{
				this.isDraggingFluxogramView = true

				this.localMousePos = {
					currentScrollX: this.$refs.workspaceScreen.scrollLeft,
					currentScrollY: this.$refs.workspaceScreen.scrollTop,
					currentMouseX: event_.clientX,
					currentMouseY: event_.clientY
				}
			}
		},

		handleScreenDrag(event_)
		{

			if(this.isDraggingFluxogramView && !this.uiState.modalIsOpen)
			{
				let dx = event_.clientX - this.localMousePos.currentMouseX
				let dy = event_.clientY - this.localMousePos.currentMouseY
  
				if(dx && dy && this.$refs.workspaceScreen)
				{
					this.$nextTick(() => {
						this.$refs.workspaceScreen.scrollTop = this.localMousePos.currentScrollY - dy
						this.$refs.workspaceScreen.scrollLeft = this.localMousePos.currentScrollX - dx
					})
				}
			}
		},

		onMouseMove(event)
		{
			this.$route.name === 'fluxogram' ? this.isFluxogramView = true : this.isFluxogramView = false

			if(this.isFluxogramView)
			{
				this.$store.dispatch('ioStore/trackMouseCoordinates', event)
			}
		},

		/**
     * @param {MouseEvent} event - HTMLMouseEvent 
     * @todo Refactor variable value to ease maintenance.
     * @description Removes a line if click event happens outside a component boundaries while the line  is being drawn.
     */
		async onWorkspaceClick(event)
		{
			if(this.mouseState.isDrawingLine)
			{
				/** If the line is being drawn it wont have a `to.id` yet. */
				let currentLineIndex = this.componentLines.indexOf(this.componentLines.find(line => line.to.id == null))

				await this.$store.dispatch('workspaceStore/spliceComponentLine', { indexOfLine: currentLineIndex })

				this.$store.dispatch('ioStore/toggleIsDrawingLine', false)
			}

			if(
				event === undefined ||
        event.target.id.includes('element') ||
        event.target.id.includes('next-segmets-menu') ||
        event.composedPath().some(el => el.id === 'interaction-view-properties-panel') ||
        [...event.target.classList].includes('handle') || // Vue draggable class for element handlers
        (event.target.parentElement && 
         event.target.parentElement.className.includes('a-form-input-container')))
			{
				this.$store.dispatch('ioStore/toggleElementSelected', true)
			}
			else
			{
				this.$store.dispatch('ioStore/toggleElementSelected', false)
			}

			if(!event.target.id.includes('embed-code-box'))
			{
				this.isEmbbedBoxVisible = false
			}
		},
		//#endregion

		//#region Component CRUD

		/**
     * Add or update a component segments' `nextSegment` property
     *
     * @param {number} componentId
     * @param {number} nextComponentId
     */
		updateComponentNextSegment(componentId, nextComponentId){

			this.$store.dispatch('workspaceStore/storeNewSegment', {
				segmentId: componentId,
				nextSegmentId: nextComponentId
			})
				.then(response => {

					let updatedComponent = response.data

					// Find the component and update component with request data.
					let indexOfComponent = this.components.findIndex(
						component => component.id === updatedComponent.id)

					this.$store.dispatch('workspaceStore/mergeUpdateComponentSegment', {
						indexOfComponent: indexOfComponent,
						segmentComponent: updatedComponent
					})

				}).catch(error => console.error(error))
		},

		/**
     * Saves all project current data.
     */
		async syncData(){
			this.uiState.isLoading = true
			this.$store.dispatch('workspaceStore/updateComponents', {
				projectId: this.currentProject.id,
				components: this.components
			})
				.then(response => {

					if(response.status === 200){
					// this.storeLsComponents();
						this.$store.dispatch('workspaceStore/toggleLoadingState')
						alert('Salvo com sucesso')
					}
        
				// Receives a status code and answer to user accordinly
				}).catch(error => console.error(error))
		},

		//#endregion

		//#region Math and Implementation methods

		/**
     * @todo Refator to implement automated testing
     */
		async watchPreview()
		{
			if(!(this.uiState.canSaveChanges && this.$store.getters['accountStore/isEditor']))
			{
				window.open(`/player/${this.currentProject.code}/preview`, '_blank').focus() // Open in new tab
			}

			await this.saveData()
			window.open(`/player/${this.currentProject.code}/preview`, '_blank').focus() // Open in new tab
		},

		displayEmbedCode(){

			this.$store.dispatch('workspaceStore/storeBatchFluxogramData', {
				projectId: this.currentProject.id,
				components: this.components
			})
				.then(() => {
					
					// Todo: create a better embed code

					this.isEmbbedBoxVisible = true
					this.embedCode = `${this.DOMAIN}/player/${this.currentProject.id}`

				}).catch(error => console.error(error))
		},

		async saveData()
		{
			this.$store.dispatch('workspaceStore/toggleLoadingState', true)
			this.$store.dispatch('workspaceStore/togglePendingSaveState', true)

			const currentView = this.$refs.currentWorkspaceView

			let viewData = null

			/** If both requests are successfull, display alert message */
			if(currentView.$el.id === 'interaction')
			{
				viewData = {
					segmentId: currentView.activeSegmentComponent.id,
					interactionId: currentView.activeSegmentComponent.interaction.id,
					data: {
						... currentView.activeSegmentComponent.interaction,
						'd_scale': this.dimensionScale,
						submit_button: {
							... currentView.activeSegmentComponent.interaction.submit_button,
							... currentView.interactionSubmitButton
						},
						elements: currentView.activeSegmentComponent.interaction.elements
					}
				}
			}

			return this.$store.dispatch('workspaceStore/saveChanges', { view: currentView.$el.id, viewData: viewData })
				.then(result => {
					// alert(result.message)
          console.log(result)
				})
				.catch(error => {

					console.error(error)
					alert(error.message)
				})
				.finally(() => {
                    
					this.$store.dispatch('workspaceStore/toggleLoadingState', false)
					this.$store.dispatch('workspaceStore/togglePendingSaveState', false)
				})
		},

		/**
     * Toggles an specifcied modal component.
     * 
     * @param {object} modal
     */
		toggleModal(modal){
			modal.isOpen = !modal.isOpen

			// If the toggle action closes it, also clear the form
			if(modal.isOpen === false)
				this.clearForm((modal))

			if(this.uiState.contextMenu.isOpen)
				this.uiState.contextMenu.isOpen = false
		},

		/**
     * Returns if a line contains valid data.
     *
     * @param {Object} pointA - Line starting point
     * @param {Object} pointB - Line finishing point
     */
		validateLine(pointA, pointB){
			let isValid = false

			// if(pointA.segment.type === "interactive")
			// bypass 'content' type conditions

			if(pointA.id === pointB.from.id || this.componentsAreConnected(pointA.id, pointB.from.id))
				return isValid // false

			return isValid = true
		},

		/**
     * Check if two components are connected to each other.
     *
     * @param {number} componentA
     * @param {number} componentB
     * @return {boolean}
     */
		componentsAreConnected(componentA, componentB){

			// see if there are matching entries in the array
			const linesA = this.getLinesByComponentId(componentA)
			const linesB = this.getLinesByComponentId(componentB)

			let connected = false

			linesA.forEach(la => {
				if(linesB.some(lb => lb.from.id === la.from.id))
					return connected = true
			})

			return connected
		},

		getLinesByComponentId(componentId){
			return this.componentLines.filter(line => line.from.id === componentId || line.to.id === componentId)
		},

		/**
     * Binds mouse position to global variable mouseState.
     * 
     * @param {MouseEvent} event - 'mouseMove' event
     * @returns void
     */
		trackMouseCoordinates(event) {
			this.mouseState.x = event.clientX
			this.mouseState.y = event.clientY
		},
    
		/**
     * Returns the center point of an DOM element.
     * 
     * @param {Object} element - HTML element to get center point.
     * 
     * @returns {Object} Object containing `x` and `y` coordinates of the center point.
     */
		getElementCenterPoint(element){

			let elementDOMRect = element.getBoundingClientRect()

			return {
				x: elementDOMRect.x + (elementDOMRect.width / 2),
				y: elementDOMRect.y + (elementDOMRect.height / 3) // 3 magic number don't know why but works
			}
		}

		//#endregion
	}
}
</script>

<style lang="scss">

/* Override default */
.a-context-menu
{
  background: #292D3E !important;
  box-shadow: 0 .25rem 1rem 0 rgba(29, 32, 44, .5);
  .a-context-menu-btn
  {
    &:hover{
      cursor: pointer;
      background:   #1D202C !important;
    }
  }
}

.a-form-title{
  text-align: start;
  margin-bottom: 1rem;
  font-weight: bold;
}

.a-form{
  text-align: start;
  display: inline-flex;
  flex-direction: column;
  & > div {
    margin: .5rem 0;
    width: 100%;
    height: 100%;
  }
  .a-row-container{
    flex-direction: row !important;
    label{
      align-self: center;
      margin-inline-end: 1rem;
    }
  }

  .a-radio{
    width: 100%;
    height: 100%;
    flex-direction: row;
    align-self: center;

    #add-component-form-is-starter{
      margin-inline-end: .5rem;
    }
  }

  input.a-form-input-text{
    min-width: 32ch;
    width: 100%;
    height: 2rem;
    &:focus{
      &::placeholder{
        position: absolute;
        top: -1rem; left: 0;
      }
    }
  }

  .a-form-input-container{
    width: 100%;
    height: 100%;
    padding: .25rem;
    display: flex;
    flex-direction: column;

    .a-radio-group{
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      margin-top: 1rem;
    }
  }

  .a-form-btn
  {
    color: white;

    &.a-btn-confirm{
      background-color: clr(act_purple);
    }

    &.a-btn-cancel{
      background-color: rgb(240, 31, 31);
    }
  }
}

.workspace
{
  width: 100%;
  height: 100%; max-height: 100vh;
  position: relative;
  user-select: none !important;

  .workspace-panel
  {
    width: 100%; height: 4rem;
    position: sticky;
    top: 0;
    z-index: 200;
    background: transparent;

    .toolbar__top
    {
      @include fit-container;

      overflow: hidden;
      position: relative;
      display: grid;
      gap: 2rem;
      grid-auto-flow: column;
      padding-inline: 4rem;
      z-index: 1000 !important;
      background: var(--clr-neutral-1000);

      box-shadow: inset 0 0 .5rem .125rem rgba(0, 0, 0, .125);

      & > *
      {
        @include fit-container;
        display: flex;
        align-items: center;
      }

      [data-container-pos=start]
      {
        justify-self: start;
      }
      [data-container-pos=center]
      {
        justify-self: center;
      }
      [data-container-pos=end]
      {
        display: flex;
        justify-self: end;
        justify-content: end;
        align-items: center;

        /* Space between the container button */
        & > *
        {
          margin: 0 1rem;
        }
      }
    }

    .embed-btn-wrapper
    {
      position: relative;

      .embed-code-box
      {
        position: absolute;
        top: 0; left: 0;
        width: 100%; height: 100%;
        background:  rgba(29, 32, 44, .5);
        input
        {
          position: relative;
        }
      }
    }

    .workspace-tab
    {
      background: var(--clr-neutral-800) !important;
    }

    .workspace-btn
    {
      padding: .5rem 1rem; margin: .25rem .5rem;
      border: none; border-radius: .25rem;

      text-decoration: none;
      color: var(--clr-neutral-100);
      background: var(--clr-purple-300);

      &:disabled
      {
        pointer-events: none;
        background: var(--clr-neutral-600);
      }
      &:hover
      {
        cursor: pointer;
        transition: .125s ease-out;
        background: var(--clr-purple-200);
      }
    }
  }

  .workspace-screen
  {
    width: 100%;
    height: calc(100% - 6rem);

    position: relative;
    overflow: auto !important; // just hides it
    background: url('../../assets/wallpapers/action-wp-03.png');
    background-attachment: local;
    background-size: cover;

    &.editing__not-allowed
    {
      &, & > *
      {

        pointer-events: none !important;
        cursor: not-allowed !important;
      }

      .workspace-background
      {
        pointer-events: none !important;
      }
    }

    &.fluxogram-view-cursor
    {
      cursor: grab;
  
      &:active
      {
        cursor: grabbing !important;
      }
    }


    /* width */
    &::-webkit-scrollbar
    {
      width: 1rem;
    }

    /* Track */
    &::-webkit-scrollbar-track
    {
      background: #292d3d;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #1D202C;
      border-radius: 20px;
      border: .25rem solid #292D3E;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover
    {
      background: lighten(#151514, 1);
    }
    
    &::-webkit-scrollbar-corner
    {
      background:#292D3E;
    }

    .workspace-screen__inner
    {
      position: absolute;
      width: 100%; height: 100%;
    }
    .workspace-background
    {
      pointer-events: all !important;
      position: fixed;
      width: inherit; height: inherit
    }
  }
  
  .workspace-bottom-bar
  {
    @include fit-container;

    position: sticky;
    width: 100%;
    height: 100%; max-height: 2.25rem;
    display: grid; grid-auto-flow: column;
    place-items: center;

    z-index: 30000;

    left: 0; bottom:  0;
    justify-content: space-between;

    // border-top: 4px solid #292D3E;
    padding: .25rem .5rem;

    color: #8d91a6;
    background: #1D202C;

    .__bottom-bar_item
    {
      padding: 0 2rem;

      small
      {
        font-size: var(--fs-200);
      }

      .warning__blocked_editing
      {
        color: #f9a825;
      }

      [highlighted_status=Completo]
      {
        color: #039be5 !important;
      }
      [highlighted_status=Aprovado]
      {
        color: #689f38 !important;
      }
      [highlighted_status=Master]
      {
        color: #8e2d78 !important;
      }
    }
  }
}
</style>
