<template>
  <div ref="fluxogramView" id="fluxogram-view" class="fluxogram-view">
    <div class="fluxgroam-top__tools_menu">

      <ActionButton style="background: #624CAB; color: white;"
      title="Adicione componentes que irão compor o fluxograma"
      @click.native="toggleAddComponentModal()">
        <template #content>
          Criar componente
        </template>
      </ActionButton>
    </div>

    <!-- Create component modal -->
    <ActionModal id="modal-create-component" v-if="addComponentForm.isOpen"
    @onOverlayClick="toggleAddComponentModal()"
    >
      <h5 class="a-form-title">Novo componente</h5>
      <form id="form-create-component" class="a-form form-create-component" @submit.prevent="">

        <div class="a-form-input-container">
          <ActionInput id="add-component-form-label" name="componentLabel"
          labelPlaceholder="Nome do componente" type="text"
          v-model="addComponentForm.name"/>
        </div>

        <!-- Radio group -->
        <template v-if="$store.getters['dashboardStore/currentProjectType'] !== 1">
          <div class="a-form-input-container">
            <label for="add-component-form-type"
            style="color: #624CAB;">Tipo do componente</label>

            <div class="a-radio-group">

              <div class="a-radio">
                <input v-model="addComponentForm.type" type="radio" id="add-component-form-type-content" name="componentType" value="content"/>
                <label for="add-component-form-type-content"
                style="color: #624CAB;">Conteúdo</label>
              </div>

              <div class="a-radio">
                  <input v-model="addComponentForm.type" type="radio" id="add-component-form-type-interactive" name="componentType" value="interactive"/>
                  <label for="add-component-form-type-interactive"
                  style="color: #624CAB;">Interativo</label>
              </div>

            </div>
          </div>
        </template>
        <!-- Radio group end -->

        <div class="a-form-input-container" style="display: inline-flex; flex-direction: row; align-items: center; width: 100%;">
          <label for="component-bkg-color" style="margin-right: 2rem; color: white;">Cor de Fundo</label>
          <input type="color" name="component-bkg-color" id="component-bkg-color" v-model="addComponentForm.background">
        </div>

        <div class="a-form-input-container">
          <ActionInput id="add-component-form-url" type="text"
           required
          v-model="addComponentForm.url"
          labelPlaceholder="ID/URL do Vídeo"/>
        </div>

        <ActionButton id="add-component-form-submit-btn"
        style="background: #624CAB; color: white;"
        @click.native="createSegment(addComponentForm)">
        <template #content>Criar novo</template>
        </ActionButton>
      </form>
    </ActionModal>

    <!-- Edit component modal -->
    <ActionModal id="modal-edit-component" v-if="editComponentForm.isOpen"
    @onOverlayClick="toggleModal(editComponentForm)">

    <act-container>
      <act-row>
        <h3 class="a-form-title">Editar componente</h3>
      </act-row>

      <act-row>
        <form class="a-form form-create-component">
          <act-row class="a-form-input-container" style="flex-direction: row;">
            <act-col :w="9">
              <action-input
              required
              type="text" id="add-component-form-label"
              label="Tag do componente"
              placeholder="Nome do componente"
              v-model="editComponentForm.name"/>
            </act-col>

            <act-col :w="3" style=" align-self: center; padding-start: 1em;">
              <input id="add-component-form-is-starter" name="componentIsInitial"
              v-model="editComponentForm.isInitial" type="checkbox" :value="true"/>
              <label for="add-component-form-is-starter">Inicial?</label>
            </act-col>
          </act-row>

          <act-row class="a-form-input-container">
            <act-col :w="12">
              <action-input id="add-component-form-url"
              type="text"
              required
              :labelPlaceholder="'ID/URL do vídeo'"
              v-model="editComponentForm.url"
              />
            </act-col>
          </act-row>

          <!-- Radio group -->
          <template v-if="$store.getters['dashboardStore/currentProjectType'] !== 1">
            <act-row class="a-form-input-container">
              <label for="add-component-form-type">Tipo do componente</label>

              <div class="a-radio-group">
                <div class="a-radio">
                  <input id="add-component-form-type-content" v-model="editComponentForm.type"
                  type="radio" name="componentTypeContent" value="content"/>
                  <label for="add-component-form-type-content">Conteúdo</label>
                </div>

                <div class="a-radio">
                  <input id="add-component-form-type-interactive" v-model="editComponentForm.type"
                    type="radio" name="componentTypeInteraction" value="interactive"/>
                  <label for="add-component-form-type-interactive">Interativo</label>
                </div>
              </div>
            </act-row>
          </template>
          <!-- Radio group end -->

          <act-row class="a-form-input-container" style="display: inline-flex; flex-direction: row; align-items: center; width: 100%;">
            <label for="component-bkg-color" style="margin-right: 2rem">Cor de Fundo</label>
            <input type="color" name="component-bkg-color" id="component-bkg-color" v-model="editComponentForm.background">
          </act-row>

          <act-row style="justify-content: flex-end">
            <act-col :w="3">
              <action-button
              class="a-form-btn a-btn-confirm"
              @click.native.prevent="$store.dispatch('submitForm', {
                formData: editComponentForm,
                callbackFn: editComponent
              })"
              :disabled="uiState.isLoading">
                <template v-slot:content>
                  {{ uiState.isLoading ? 'Salvando' : 'Salvar' }}
                </template>
              </action-button>
            </act-col>

            <act-col :w="3">
              <action-button class="a-form-btn a-btn-cancel"
              @click.native.prevent="toggleModal(editComponentForm)"
              :disabled="uiState.isLoading">
                <template v-slot:content>
                  Cancelar
                </template>
              </action-button>
            </act-col>
          </act-row>
          </form>
        </act-row>
      </act-container>

    </ActionModal>

    <div style="width: 3000px !important; height: 2000px !important;">



      <div class="act-svg-container__wrapper" style="margin-top: -4rem">

        <svg class="a-svg-container" v-for="(line, lineIndex) in componentLines" :key="lineIndex">

          <!-- Devnote: Now uses cubic curve C for testing and maintenance purposes.
          Some uses cases still have to be analyzed and adapted.

          See: https://developer.mozilla.org/en-US/docs/Web/SVG/Tutorial/Paths
          -->
          <defs>
            <!-- :orient="uiState.vecAngle * 90" -->
            <marker id="arrow-end" refX="3.75" refY="2"
                :class="['a-svg-path-marker']"
                markerWidth="40" markerHeight="40"
                orient="auto"
                >
              <path d="M 0 0 L 4 2 L 0 4 z"/>
            </marker>
          </defs>

          <defs>
            <marker id="arrow-mid" refX="-20" refY="2"
                class="a-svg-path-marker"
                markerWidth="30" markerHeight="30"
                orient="auto">
              <path d="M 0 0 L 4 2 L 0 4 z"  />
            </marker>
          </defs>

          <!-- By placing the v-for into path element instead of svg, it causes the delete buttons to scatter
              in the same document height as the svg layer, the components and context menus.
              While it doesnt seem to cause any side effects as of 12/07/22, it appears to be a bad idea to have
              them ungrouped like that. It is a good idea to keep an eye out in case things just break and you dont know why.-->
          <!-- <path :id="`a-svg-path-${lineIndex}`" class="a-svg-path" v-for="(line, lineIndex) in componentLines" :key="lineIndex"
            :style="`stroke: ${ line.color ? line.color : 'orange' }`"
            :d="`M ${(line.from.x)} ${line.from.y}
            C ${line.to.x || mouseState.x + dragOffset.x },${line.from.y} ${line.from.x}, ${line.to.y || mouseState.y + dragOffset.y},
            ${line.to.x || mouseState.x + dragOffset.x},${line.to.y || mouseState.y + dragOffset.y}`"
            fill="none"
            marker-end="url(#arrow)"
          /> -->

          <path :id="`a-svg-path-${lineIndex}`" class="a-svg-path"
            :style="`stroke: ${ line.color ? line.color : 'orange' }`"
            :d="`M ${(line.from.x)} ${line.from.y}
            ${line.to.x || mouseState.x + dragOffset.x},${line.to.y || mouseState.y + dragOffset.y}`"
            fill="none"
            marker-mid="url(#arrow-mid)"
            marker-end="url(#arrow-end)"
          />

          <!-- Old circles used in place of the arrow heads. Used to identify the vector direction. -->
          <!-- <circle :cx="line.from.x" :cy="line.from.y" r="4" fill="blue"/>
          <circle :cx="line.to.x" :cy="line.to.y" r="4" fill="green"/> -->

        </svg>

        <button class="workspace-btn a-component-line-btn" v-for="line in componentLines" :key="line.id"
        :style="`left: ${Math.sqrt(line.to.x * line.from.x) - 10}px; top: ${Math.sqrt(line.from.y * line.to.y) - 75}px; color: ${line.color ? 'white' : 'black'}; background: ${ line.color ? line.color : 'orange' };`"
        @click.prevent="deleteComponentNextSegment(line)">
          ✖
        </button>

      </div>

      <div style="margin-top: 4rem"
      :class="
      [
        'a-component-container',
        { 'editing__not-allowed' : !canEditProject }
      ]"
      @click.prevent="onComponentClick($event)">


        <VueDraggableResizable v-for="(component, componentIndex) in components" :key="component.id"
        :class="['a-component', { 'editing__not-allowed' : !canEditProject }]"
        :style="`box-shadow: 0 .25rem .5rem .125rem ${ component.background ? component.background : 'gainsboro' }`"

        :id="`a-component-${component.id}`"
        :parent="true"
        :resizable="false"
        :w="200"
        :x="component.screenCoordinates.x"
        :y="component.screenCoordinates.y"
        @dragging="onComponentDrag(component)"
        @dragstop="onComponentDragStop"
        @mousedown.native.prevent="onComponentMouseDown(componentIndex)"
        >

          <img v-if="component.isInitial"
          alt="starter"
          title="Inicial"
          src="../../assets/ui/cmp-str-flag.png"
          class="a-component-flag a-component-isinitial-flag">

          <img v-if="component.type === 'interactive'"
          alt="interactive"
          title="Interativo"
          class="a-component-flag a-component-interactive-flag"
          src="../../assets/ui/cmp-int-flag.png">

          <p class="a-component-label">{{ component.name }}</p>
          <div class="a-component-thumb-container">
            <iframe class="a-component-thumb"
              :src="!component.source.includes('https') ? 
                `https://player-vz-4cab7bf9-47f.tv.pandavideo.com.br/embed/?v=${component.source}` :
                `https://player-vz-4cab7bf9-47f.tv.pandavideo.com.br/embed/?v=${component.source.match(/([a-f0-9\-]{36})/)[0]}`"
              cross-origin frameborder="0"></iframe>
          </div>

          <!-- Floating actions panel -->
          <div id="float-panel__actions"
          :class="['a-component__float-panel', { 'float-panel__visible' : componentSelected === componentIndex }]">

            <!-- Connect component -->
            <div id="float-panel-btn__connect" class="a-component__float-panel_buttons">
              <img
              title="Criar conexão entre componentes"
              @click.prevent.stop="connectComponentPolyfill($event)"
              class="a-component__float-panel-icon"
              style="height: 90%; padding-block: .75rem"
              src="@/assets/ui/cmp-arrow-conn.png" alt="Editar componente">
            </div>

            <!-- Edit component -->
            <div id="float-panel-btn__edit" class="a-component__float-panel_buttons">
              <img
              title="Editar"
              @click.prevent="openComponentEditForm(components[componentSelected])"
              class="a-component__float-panel-icon"
              src="@/assets/ui/cmp-cross-edt.png" alt="Editar componente">
            </div>

            <!-- Remove component -->
            <div id="float-panel-btn__remove" class="a-component__float-panel_buttons">
              <img
              title="Remover"
              @click.prevent="deleteComponent"
              class="a-component__float-panel-icon"
              src="@/assets/ui/cmp-cross-rm__alt.webp" alt="Remover componente">
            </div>
          </div>

        </VueDraggableResizable>
      </div>
    <!-- Todo: refactor into a single file component -->
    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex'
import VueDraggableResizable from 'vue-draggable-resizable'
import { ActionModal, ActionButton, ActionInput /* ContextMenu */ } from '../../components'
import ActContainer from '../../components/layout/ActContainer.vue'
import ActCol from '../../components/layout/ActCol.vue'
import ActRow from '../../components/layout/ActRow.vue'
import toggleMixin from '../../mixins/toggleMixin.vue'


function defaultState ()
{
	return {
		workspaceScreenEl: null,
		dragOffset: { x: 0, y: 0 },
		colorPickerShown: false,
		componentPos: null,
		componentSelected: 0,

		/** Unify modal property mapping */
		addComponentForm: {
			isOpen: false,
			name: null,
			type: 'content',
			background: '#EEEEEE',
			url: null
		},
		editComponentForm:{
			isOpen: false,
			id: null,
			name: null,
			type: null,
			url: null,
			background: '#EEEEEE',
			isInitial: null
		},
		canEditProject: true
	}
}

export default {
	name: 'FluxogramView',
	extends: toggleMixin,

	components: { VueDraggableResizable, /* ContextMenu ,*/ ActionModal, ActionButton, ActionInput, ActCol, ActRow, ActContainer },
	data(){
		return defaultState()
	},

	computed: {
		...mapState({
			dashboardStore: state => state.dashboardStore,

			components: state => state.workspaceStore.components,
			componentLines: state => state.workspaceStore.componentLines,

			uiState: state => state.workspaceStore.uiState, // Todo: Refactor uiState into its own store module
			mouseState: state => state.ioStore.mouseState
		}),

		enteringRoute()
		{
			return this.$route
		}
	},

	mounted() {

		/** Prevents drawing state to lock application on page reload. */
		this.$store.dispatch('ioStore/toggleIsDrawingLine', false)

		this.addControlEventListenters()

		/**
     * Runs only once after mounted. Checks for segment connections and draws them.
     * User setTimeout other solutions like $nextTick and $forceUpdate didnt work.
     *
     * Also, 750ms seems to be the smaller number possible for it to work.
     * I think it implies it takes around 750ms to actually load the elements.
    */
		setTimeout(() => {

			if(this.componentLines.length === 0)
			{
				this.loadLines()

			}
		}, 1000)

		this.calcDrawAngle(this.componentLines)

		this.canEditProject = this.$store.getters['globalIsProjectEditable']
	},

	methods: {

		//#region Setup

		/**
     * @todo Refactor into utility function
     * @param {String} hexString
     */
		hexToSafeSVG(hexString)
		{
			return hexString.replace('#', '%23')
		},

		addControlEventListenters()
		{
			addEventListener('click', this.onMouseClick, true)
			addEventListener('mousemove', this.onMouseMove, true)

			const wrksDrag = document.getElementById('draggableWorkspaceScreen')
			if(wrksDrag)
			{
				wrksDrag.addEventListener('scroll', (/* event */) => {

					this.dragOffset = { x: wrksDrag.scrollLeft, y: wrksDrag.scrollTop }
				})
			}
		},

		//#endregion

		//#region Form stuff
		openComponentEditForm(formValues)
		{
			this.setFormInitialValues(formValues)
			this.toggleModal(this.editComponentForm)
		},

		/**
     * Set initial values for component editing form.
     * @param {{ id: number, name: string, type: string, isInitial: boolean, source: string } values
     *        - Values to be assigned to the form fields.
     */
		setFormInitialValues(values)
		{
			this.editComponentForm.id = values.id
			this.editComponentForm.name = values.name
			this.editComponentForm.type = values.type
			this.editComponentForm.url = values.source

			this.editComponentForm.isInitial = values.isInitial
		},

		/**
     * Creates a new component element.
     *
     * @param {{ label: string, type: 'Content'|'Interactive', url: string}} component -
     *        The component element visible to the user.
     */
		async createSegment(componentFormData) {

			const data = {
				... componentFormData,
				url: this.parseURL(componentFormData.url)
			}

			await this.$store.dispatch('workspaceStore/storeSegment',
				{
					projectId: this.dashboardStore.currentProject.code,
					data: data,
					options: { dragOffsetX: this.dragOffset.x, dragOffsetY: this.dragOffset. y }
				})
				.finally(() => this.toggleAddComponentModal())
		},

		/**
     * Temporary solution to handle vimeo player unsuported url.
     * By default its validated against 'vimeo.com/manage/videos/'
     */
		parseURL(urlToValidate, invalidString =  'vimeo.com/manage/videos/')
		{
			return urlToValidate.includes(invalidString) ? urlToValidate.replace(invalidString, 'player.vimeo.com/video/') : urlToValidate
		},

		async editComponent(componentFormData)
		{
			const data = { ... componentFormData, url: this.parseURL(componentFormData.url) }

			const action = await this.$store.dispatch('workspaceStore/updateSegment', { segmentId: componentFormData.id, data: data })

			this.toggleModal(this.editComponentForm)

			if(action.status === 'error')
			{
				return alert('Ocorreu um erro ao realizar as alterações.')
			}

			/** @todo: This snippet can be optmized by not clearing the lines array and just slicing the necessary ones. */
			this.$store.dispatch('workspaceStore/clearComponentLines')

			this.loadLines()

			return alert('Alterações salvas com sucesso.')
		},
		//#endregion

		//#region Component CRUD

		/**
     * Add or update a component segments' `nextSegment` property
     *
     * @param {number} componentId
     * @param {number} nextComponentId
     */
		async updateSegmentEdges(componentId, nextComponentId)
		{
			const action = await this.$store.dispatch('workspaceStore/updateNextSegmentProp',
				{
					segmentId: componentId,
					nodeId: nextComponentId
				})

			if(action.status === 'error')
			{
				/** @todo handle remove lines */
				alert('Um erro ocorreu ao conectar os componente')
			}
		},

		/**
     * Removes a segment reference from a specified component's nextSement property.
     *
     * @param {object} line - Line connecting the components. Represents the reference in the 'nextSegment' property.
     */
		async deleteComponentNextSegment(line){

			const action = await this.$store.dispatch('workspaceStore/unsetNextSegmentProp', { lineObj: line })

			if(action.status === 'error')
			{
				alert('Um erro ocorreu ao efetuar a operação')
				return this.$forceUpdate()
			}
		},

		/**
     * Deletes an existing component and lines connected to it.
     *
     * @returns void
     */
		async deleteComponent()
		{
			if(!confirm('O componente selecionado será permanentemente removido. Deseja realmente removê-lo?'))
				return

			const component = this.components.at(this.componentSelected)

			const action = await this.$store.dispatch('workspaceStore/destroySegment',
				{
					projectId: this.dashboardStore.currentProject.code,
					segmentId: component.id
				})

			/** This enables loggins statuses for different cases */
			if(action.error === 'error')
			{
				return alert('Um erro ocorreu ao remover o segmento.')
			}
		},

		/**
     * Saves all project current data.
     */
		async syncData()
		{
			const action = await this.$store.dispatch('workspaceStore/storeBatchFluxogramData', {
				projectId: this.dashboardStore.currentProject.id,
				components: this.components
			})

			if(action.status === 'success')
			{
				alert('Salvo com sucesso') // this.storeLsComponents();
			}
		},

		//#endregion

		//#region Component Controls

		/**
     * Called whenever a `mousedown` event is called inside a component.
     *
     * @param {number} componentIndex
     *
     */
		onComponentMouseDown(componentIndex)
		{
			/**
       * Called in `@mousedown` event, so that 'componentSelected' value
       * is updated by the time the @onDrag event is called
       *
       * @inner
       * @type {number}
       */
			this.componentSelected = componentIndex
		},

		/**
     * Called whenever a `click` event is called in a component inside Fluxogram View.
     *
     * @param {MouseEvent} event - HTMLMouseEvent
     * @todo Refactor variable value to ease maintenance.
     */
		onComponentClick(event)
		{
			if(!this.mouseState.isDrawingLine)
			{
				return
			}

			const element = event.target

			const component = this.components.find(
				// Removes html identifier prefix
				comp => comp.id === parseInt(element.id.split('a-component-').pop())
			)

			/** Line that is being drawn */
			let lineAtLastIndex = this.componentLines.find(line => line.to.id == null)

			const lineAtLastIndexComponent = this.components.find(
				comp => comp.id === lineAtLastIndex.from.id)

			if(!this.validateLine(lineAtLastIndexComponent, component))
			{
				// Todo: Verify if there is a necessity to execute further actions
				return console.warn('%c conexão invalida', 'color: red; font-weight: bold')
			}

			/** Confirmation logging */
			console.warn('%c conexão válida', 'color: green; font-weight: bold;')

			/** Updates line that is being drawn */
			const elementCenterPoint = this.getElementCenterPoint(element)
			lineAtLastIndex = {
				...lineAtLastIndex,
				to: {
					id: component.id,
					x: elementCenterPoint.x, y: elementCenterPoint.y
				}
			}
			/** Updates line array element */
			this.$store.dispatch('workspaceStore/mergeUpdateComponentLine',
				{
					indexOfLine: this.componentLines.length - 1,
					line: lineAtLastIndex
				}).then(() => {

				this.calcDrawAngle(this.componentLines)

				/** Handle the whole line process before it actually updates. Should add fallback method */
				this.updateSegmentEdges(lineAtLastIndexComponent.id, component.id) // Add

				this.$store.dispatch('ioStore/toggleIsDrawingLine', false)
				this.$store.dispatch('workspaceStore/togglePendingSaveState', true)
			})
		},

		connectComponentPolyfill(event)
		{
			/** Relative to the floating actions panel */
			let offsettedComponent = event.target.offsetParent.offsetParent

			this.onComponentDBClick(event, offsettedComponent)
		},

		/**
     * Called whenever a `dbclick` event is called inside a component.
     *
     * @param {MouseEvent} event HTMLMouseEvent
     * @param {HTMLElement?} eventTarget Optional override for event target
     */
		onComponentDBClick(event, eventTarget = null){

			/**
       *  Full implementation will take the component's segment nexSegment property
       *  to draw lines on load. To create new lines, this method will return the
       *  value of the first component to be clicked to be used in another method
       *  that will send to the backend the two segments to be linked together
       *
       */

			let element = event.target

			if(eventTarget !== null)
				element = eventTarget

			const elementCenterPoint = this.getElementCenterPoint(element)

			const hasCreatedNewLine = this.drawLine({
				id: parseInt(element.id.split('a-component-').pop()),
				x: elementCenterPoint.x, y: elementCenterPoint.y
			})

			if(hasCreatedNewLine)
				this.$store.dispatch('ioStore/toggleIsDrawingLine', true)

		},

		/**
     * Called whenever a component is being dragged.
     *
     * @param {object}  component
     *
     * @todo Analyse the performance side-effects of this function call.
     */
		onComponentDrag(component){
			/**
       * Every frame a component is being dragged this method is called.
       * Has to update the values of the lines in the lines array.
       *
       * For performance, check if a value has changed and only update it if it has
       *
       */
			let componentDOMElement = document.getElementById(`a-component-${component.id}`)

			let componentDOMRect = componentDOMElement.getBoundingClientRect()

			componentDOMRect = {
				x: componentDOMRect.x + this.dragOffset.x,
				y: componentDOMRect.y + this.dragOffset.y,
				height: componentDOMRect.height,
				width: componentDOMRect.width
			}

			/**
       * (Data-test) Represents a selected component DOM coordinates
       *
       * @type {{w: number, x: number, h: number, y: number}}
       */
			this.componentPos = { x: componentDOMRect.x, y: componentDOMRect.y, w: componentDOMRect.width, h: componentDOMRect.height }

			/**
       * Following function call is more 'correct' but causes offset errors on load. Prefer using the second method.
       */

			this._updateComponentCoordinates(componentDOMRect.x, (componentDOMRect.y - (componentDOMRect.width / 2)))

			const componentHasLine = this.checkComponentHasLine(component.id)

			if(componentHasLine)
			{
				// Wraps elements in an array in case there is only one element
				const componentLines = this._getLinesByComponentId(component.id)

				this.calcDrawAngle(componentLines)
			}
		},

		/**
     * Called whenever a component is `dropped` from a dragging event
     */
		onComponentDragStop()
		{
			if(this.uiState.canSaveChanges === false)
			{
				this.$store.dispatch('workspaceStore/togglePendingSaveState')
			}
		},

		//#endregion

		//#region Math and Implementation methods

		/**
     * @todo Refator to implement automated testing
     */
		watchPreview(){
			this.$store.dispatch('workspaceStore/storeBatchFluxogramData', {
				projectId: this.dashboardStore.currentProject.id,
				components: this.components
			})
				.then(() => {
					window.open(`/player/${this.dashboardStore.currentProject.id}`)

				}).catch(error => console.error(error))
		},

		/**
     * Toggles an specifcied modal component.
     *
     * @param {object} modal
     */
		toggleModal(modal)
		{
			modal.isOpen = !modal.isOpen

			this.$store.dispatch('workspaceStore/toggleModalIsOpen', modal.isOpen)
		},

		/**
     * Clears a form input field values.
     * @param {Object} formToClear - Form to be cleared.
     */
		clearForm(formToClear)
		{
			Object.keys(formToClear).forEach(key => {
				if(key !== 'isOpen') formToClear[key] = null
			})
		},

		/**
		 * Wrapper method to set default values when adding components
		 */
		toggleAddComponentModal()
		{
			this.toggleModal(this.addComponentForm)

			if(!this.addComponentForm.isOpen)
			{
				this.addComponentForm = defaultState().addComponentForm
			}
		},

		saveData(){
			this.$store.dispatch('workspaceStore/storeBatchFluxogramData', {
				projectId: this.dashboardStore.currentProject.id,
				components: this.components
			})
		},

		/**
     * Returns if a line contains valid data.
     *
     * @param {Object} fromPoint - Line starting point
     * @param {Object} toPoint - Line finishing point
     */
		validateLine(fromPoint, toPoint)
		{
			let isValid = false

			/** Cannot point to itself */
			if(fromPoint.id === toPoint.id)
				return isValid // false

			/** Already exists */
			if(this.componentLines.find(line => {
				return line.from.id === fromPoint.id && line.to.id === toPoint.id
			}))
				return isValid // false

			/** Type specific validations: Content */
			if(fromPoint.type === 'content')
			{
				console.log('%ctype is content', 'color: blue;')

				/** Must have only one output */
				if(this.componentLines.filter(line => line.from.id === fromPoint.id).length > 1)
					return isValid // false
			}

			return (isValid = true)
		},

		/**
     * Checks if an existing line matches a component.
     *
     * @param {number} componentId
     * @return {boolean} - Returns `true` if the component has a line connected to it.
     */
		checkComponentHasLine(componentId)
		{
			return this.componentLines.some(line => line.to.id || line.from.id === componentId)
		},

		/**
     * Get existing lines that match a component.
     *
     * @param {number} componentId
     * @return {Array<Object>}  Array of lines that are connected to a component.
     */
		_getLinesByComponentId(componentId)
		{
			return this.componentLines.filter(line => line.from.id === componentId || line.to.id === componentId)
		},

		/**
     * Update component position to screen coordinates.
     *
     * @param {number} x - X screen coordinate.
     * @param {number} y - Y screen coordinate.
     *
     * @todo Refator - At the moment it is updating the state directly
     */
		_updateComponentCoordinates(x, y){
			this.components[this.componentSelected].screenCoordinates.x = parseInt(x)
			this.components[this.componentSelected].screenCoordinates.y = parseInt(y)
		},

		/**
     * Draws lines for components that already have a nextSegment value.
     * @return {void}
     */
		loadLines()
		{
			this.components.forEach(component => {

				const nextSegment = component.nextSegment

				Object.keys(nextSegment).forEach(key => {
					if(nextSegment[key]){

						let componentDOMRect =  this.getElementCenterPoint(document.getElementById(`a-component-${component.id}`))

						// Uses nextSegment component as pointB
						let nextSegmentComponent = this.components.find(component => component.id === nextSegment[key])

						let nxtComponentDOMRect = this.getElementCenterPoint(document.getElementById(`a-component-${nextSegmentComponent.id}`))

						this.drawLine({
							id: component.id,
							x: componentDOMRect.x, y: componentDOMRect.y},
						{
							id: nextSegmentComponent.id,
							x: nxtComponentDOMRect.x, y: nxtComponentDOMRect.y
						})
					}
				})
			})

			this.calcDrawAngle(this.componentLines)
		},

		/**
     *  Starts drawing a new line from the specified xy coordinates.
     *
     *  @param {Object} pointA - Line starting point. Accepts an `id` and `x`,`y` coordinates.
     *  @param {string} pointA.id - Starting point identifier.
     *  @param {number} pointA.x - Starting point x coordinate.
     *  @param {number} pointA.y - Starting point y coordinate.
     *  @param {Object?} pointB - Line ending point. Can be null. Accepts an `id` and `x`,`y` coordinates.
     *  @param {string} pointB.id - Ending point identifier.
     *  @param {number} pointB.x - Ending point x coordinate.
     *  @param {number} pointB.y - Ending point y coordinate.
     *  @returns {Object} New line object.
     */
		drawLine(pointA = {id: null, x: null, y: null}, pointB = {id: null, x: null, y: null}){

			return this.componentLines.push({
				color: 'orange',
				from: { id: parseInt(pointA.id), x: pointA.x, y: pointA.y },
				to: { id: pointB.id, x: pointB.x, y: pointB.y }
			})
		},

		/**
     * @typedef {{ color: string, to: { id: number, x: number, y: number }, from: { id: number, x: number, y: number }} lines
     * @param {Array<lines>} linesArray
     *        - Array of elements to calculate vector positions from. Elements must match the line object structure.
     */
		calcDrawAngle(linesArray){

			let interactiveComponents = this.components.filter(el => el.type === 'interactive')

			// console.time('perf01')
			linesArray.forEach(point => {

				/** Initialize spacial variables */

				let elA = document.getElementById(`a-component-${point.from.id}`)
				let aDomRect = elA.getBoundingClientRect()
				Object.assign(aDomRect, {  x: aDomRect.x + this.dragOffset.x, y: aDomRect.y + this.dragOffset.y })

				let aCenterPoint = this.getElementCenterPoint(elA)


				let elB = document.getElementById(`a-component-${point.to.id}`)

				let bDomRect = elB.getBoundingClientRect()
				Object.assign(bDomRect, { x: bDomRect.x + this.dragOffset.x, y: bDomRect.y + this.dragOffset.y })

				let bCenterPoint = this.getElementCenterPoint(elB)

				let dy = bCenterPoint.y - aCenterPoint.y
				let dx = bCenterPoint.x - aCenterPoint.x


				/** Assign conditional color to line based on correct/incorrect answer */
				let color = '#DDBB12' // orange
				if(interactiveComponents && interactiveComponents.length > 0)
				{
					let interactivePreviousSegment = interactiveComponents.find(seg => seg.id === point.from.id)

					if(interactivePreviousSegment)
					{
						const { output: { right: correctId } } = interactivePreviousSegment.interaction

						correctId && correctId === point.to.id ?
							color = '#33AB25' /* green */ :
							color = '#EE3325' /* red */
					}
				}

				/** Property ```color``` only used in the frontend. See type definition for lines */
				point.color = color

				/** Assign dx and dy line positions and curvature based on angle */

				this.uiState.vecAngle = (Math.atan2(dy, dx) * 2 / Math.PI)

				/** Top */
				if(this.uiState.vecAngle > 0.25 && this.uiState.vecAngle < 1.6)
				{
					point.to.x = bCenterPoint.x
					point.to.y = bCenterPoint.y - (bDomRect.height/2)

					/** Top-left */
					if(this.uiState.vecAngle < 0.6)
					{
						point.from.x = aCenterPoint.x + (aDomRect.width/2)
						point.from.y = aCenterPoint.y
						return
					}
					/** Top right */
					if(this.uiState.vecAngle > 1.4)
					{
						point.from.x = aCenterPoint.x - (aDomRect.width/2)
						point.from.y = aCenterPoint.y
						return
					}

					point.from.x = aCenterPoint.x
					point.from.y = aCenterPoint.y + (aDomRect.height/2)
					return
				}

				// Right
				if(this.uiState.vecAngle > 1.6 || this.uiState.vecAngle < -1.5){
					point.from.x = aCenterPoint.x - (aDomRect.width/2)
					point.from.y = aCenterPoint.y

					point.to.x = bCenterPoint.x + (bDomRect.width/2)
					point.to.y = bCenterPoint.y
				}

				// Bottom-right
				if(this.uiState.vecAngle < -1.3 && this.uiState.vecAngle > -1.5){
					point.from.x = aCenterPoint.x
					point.from.y = aCenterPoint.y - (aDomRect.height/2)

					point.to.x = bCenterPoint.x + (bDomRect.width/2)
					point.to.y = bCenterPoint.y
				}

				// Bottom
				if(this.uiState.vecAngle > -1.3 && this.uiState.vecAngle < -0.5){
					//
					point.from.x = aCenterPoint.x
					point.from.y = aCenterPoint.y - (aDomRect.height/2)

					point.to.x = bCenterPoint.x
					point.to.y = bCenterPoint.y + (bDomRect.height/2)
				}

				// Bottom left
				if(this.uiState.vecAngle > -0.5 && this.uiState.vecAngle < -0.3){
					point.from.x = aCenterPoint.x
					point.from.y = aCenterPoint.y - (aDomRect.height/2)

					point.to.x = bCenterPoint.x - (bDomRect.width/2)
					point.to.y = bCenterPoint.y
				}

				//Left
				if(this.uiState.vecAngle > -0.3 && this.uiState.vecAngle < 0.5){
					point.from.x = aCenterPoint.x + (aDomRect.width/2)
					point.from.y = aCenterPoint.y

					point.to.x = bCenterPoint.x - (bDomRect.width/2)
					point.to.y = bCenterPoint.y
				}

			})
			// console.timeEnd('perf01')
		},

		/**
     * Returns the center point of an DOM element.
     *
     * @param {Object} element - HTML element to get center point.
     *
     * @returns {Object} Object containing `x` and `y` coordinates of the center point.
     */
		getElementCenterPoint(element)
		{
			let elementDOMRect = element.getBoundingClientRect()

			// console.log(this.$refs.fluxogramView.scrollLeft)
			Object.assign(elementDOMRect, { x: elementDOMRect.x + this.dragOffset.x, y: elementDOMRect.y + this.dragOffset.y })

			return {
				x: elementDOMRect.x + (elementDOMRect.width / 2),
				y: elementDOMRect.y + (elementDOMRect.height / 3) // 3 magic number don't know why but works
			}
		}

		//#endregion
	}
}
</script>

<style lang="scss">

.fluxogram-view
{
  width: 3000px !important; height: 2000px !important;
  overflow: hidden !important;

  .act-modal
  {
    background-color: #171717 !important;
    color: white;
  }

  .a-form-title
  {
    color: clr(act_purple);
  }
}

.fluxgroam-top__tools_menu{

  width: 100%; height:100%;
  padding: .5rem 4rem;
  max-height: 3rem;
  position: fixed;
  box-shadow: 0 0 .5rem 0 #343743;
  background: #1D202C;
  z-index: 999;

}

.component-attr-container{

  position: fixed;
  width: 100%;
  max-width: 8rem;
  z-index: 9999999999999999999999 !important;
  // top: 3rem;
  min-height: 2rem;
  background: #1D202C;
  border-bottom: 2px solid #343743;
}
.act-svg-container__wrapper
{
  width: 100%; height: 100%;
  position: absolute;
}



.a-component__float-panel{
  position: absolute;
  max-width: 20%;
  top: 5%; right: -25%;

  visibility: hidden;

  &.float-panel__visible{

  visibility: visible !important;
  }

  .a-component__float-panel_buttons{
    width: 100%;
    background: #1D202C;
    border-radius: 50%;
    z-index: auto;

    .a-component__float-panel-icon{
      display: flex;
      width: 100%;
      padding: .5rem;
      filter: invert(1) brightness(.75);

      &:hover{
        cursor: pointer;
        transition: ease-out .5s;
        filter: invert(1) brightness(.85);
      }
    }
  }
}


.a-svg-container{
  position: absolute;
  width: 100%; height: 100%;
  margin: -4rem 0; // Offset toolbar height
  top: 0; left: 0;
  z-index: 96;
  pointer-events: none;
  background: transparent;

  /** Todo: Add a stroke so arrow has rounded corners. */
  marker.a-svg-path-marker{
    border: none;
    fill: darken(#df9e1c, 6) !important;
  }

  path.a-svg-path{
    stroke-width: 4px;
    stroke-linejoin: round;
    stroke-linecap: round;
    stroke: #e2b027;
  }
    & > * {
      position: absolute;
      z-index: auto;
    }
}

.a-component-line-btn
{
  position: absolute;
  padding: .25rem .5rem;
  z-index: 95 !important;
  border: none; border-radius: 1rem !important;

  font-weight: bold;
  background: orange;

  &:hover
  {
    cursor: pointer !important;
    filter: brightness(1.1);
  }
}
.a-component-container
{
  width: 100%;
  z-index: 98 !important;
  height: 100%;
  position: relative;
  pointer-events: none !important;
}

.a-component
{
  width: 200px !important; height: min-content !important;
  pointer-events: all !important;
  border: none; border-radius: .5rem;

  padding: .5rem 1rem 1rem 1rem;

  box-shadow: 0 .25rem .5rem .125rem rgba(0, 0, 0, .2);
  background: linear-gradient(to bottom, #292D3E, #1D202C);
  z-index: 99 !important;

  &.editing__not-allowed
  {
    &, & > * {

      pointer-events: none !important;
      cursor: not-allowed !important;
    }
  }

  &.active.dragging
  {
    cursor: grabbing !important;
  }
  *
  {
    margin: .5rem 0 0 0;
  }

  .a-component-label
  {
    width: 100%;
    padding: .25rem 0;
    pointer-events: none;
    z-index: 1000 !important;
    border-radius: .25rem;

    text-align: center; text-overflow: ellipsis; // Temporary solution. Gotta limit characters

    color: var(--clr-neutral-100);
    background: var(--clr-neutral-900);
  }

  .a-component-thumb-container
  {
    width: 100%; height: min-content;
    display: inline-block;

    .a-component-thumb
    {
      width: 100%; height: 100px;
    }
  }

  /** Component flags */
  img
  {
    &.a-component-flag
    {
      overflow: visible;
      position: absolute;
      top: -.5rem;
      max-width: 1rem;
      margin: 0 !important;

      &:hover
      {
        cursor: pointer !important;
      }

      &.a-component-isinitial-flag
      {
        left: 0;
        max-width: 1.2rem;
        margin: .75rem 0 0 .25rem !important;
        filter: brightness(5);
      }

      &.a-component-interactive-flag
      {
        left: calc(100% - .5rem);
        margin: .75rem -.75rem !important;
        filter:
        brightness(1)
        contrast(.8)
        hue-rotate(-30deg)
      }
    }
  }
}
</style>
