import { render, staticRenderFns } from "./modal-body-2.vue?vue&type=template&id=662c1370&scoped=true&"
import script from "./modal-body-2.vue?vue&type=script&lang=js&"
export * from "./modal-body-2.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "662c1370",
  null
  
)

export default component.exports