import store from '../store'

import router from '.'

import { AccountAppSettings, AccountUserList, ActionPlayer, Dashboard, DashboardAccount, DashboardHomeView, DashboardLibrary, DashboardOperations, DashboardOperationsCreditHistory, DashboardOperationsList, DashboardPartners, DashboardPlaylist, DashboardPlaylistList, DashboardPlaylistView, DashboardProjects, DashboardReports, DashboardsOperationsCreditManagement, FluxogramView, InteractionView, Login, RecoveryPassword, Support, Workspace } from '../views'
import AccountInviteConfirmation from '../views/guest/AccountInviteConfirmation.vue'
import AccountInviteRegistration from '../views/guest/AccountInviteRegistration.vue'

export default [
	{
		path: '/',
		name: 'Login',
		component: Login,
		beforeEnter: (to, from, next) => {
			store.dispatch('authStore/isUserLogged').then(isAuthenticated => {

				try {
					isAuthenticated ? next({ path: '/dashboard' }) : next()
					
				} catch (error) {
					/** Supress error when redirecting on navigation guard */	
				}

			})
		}
	},
	{
		path: '/dashboard',
		component: Dashboard,
		children: [
			{
				path: '',
				component: DashboardHomeView,
				children: [
					{
						path: '/',
						component: () => {
							if (store.getters['accountStore/isPartner']) {
								return import('../views/dashboard/home/PartnerHome.vue')
							}

							return import('../views/dashboard/home/EditorHome.vue')
						}
					}
				]
			},
			{
				path: 'projects',
				name: 'dashboardProjects',
				component: DashboardProjects,
				beforeEnter: async (to, from, next) => {

					if (
						to.matched.some(record => record.path.match(/^\/dashboard(?:\/(?=$))?$/i)
							&& store.getters['accountStore/isClient'])
					) {
						return next({ path: '/dashboard/library' })
					}

					next()
				}
			},
			{
				path: 'partners',
				name: 'dashboardPartners',
				component: DashboardPartners,
				beforeEnter: async (to, from, next) => {

					if (store.getters['accountStore/isPartner'] || store.getters['accountStore/isMaster']) {
						return next()
					}

					return next({ path: '/dashboard' })
				}
			},
			{
				path: 'library',
				name: 'dashboardLibrary',
				component: DashboardLibrary
			},
			{
				path: 'playlists',
				component: DashboardPlaylistView,
				beforeEnter: async (to, from, next) => {

					if (store.getters['accountStore/isProducer'] || store.getters['accountStore/isPartner'] || store.getters['accountStore/isEditor']) {
						return next()
					}

					return next({ path: '/dashboard' })
				},
				children: [
					{
						path: '',
						name: 'dashboardPlaylistList',
						component: DashboardPlaylistList
					},
					{
						path: ':hash',
						name: 'dashboardPlaylist',
						component: DashboardPlaylist,
						props: true
					}
				]
			}
		],
		meta: { requiresAuth: true }
	},
	{
		path: '/account',
		component: DashboardAccount,
		children: [
			{
				path: '/',
				name: 'accountUsers',
				component: AccountUserList
			},
			{
				path: 'applications',
				name: 'accountApplications',
				component: AccountAppSettings
			},
			{
				path: 'operations',
				component: DashboardOperations,
				children: [
					{
						path: '/',
						component: DashboardOperationsList
					},
					{
						path: 'credit-management',
						component: DashboardsOperationsCreditManagement
					},
					{
						path: 'credit-history',
						component: DashboardOperationsCreditHistory
					},
					{
						path: 'reports',
						component: DashboardReports
					}
				]
			}
		],
		meta:{ requiresAuth: true }
	},
	{
		path: '/workspace/:projectId',
		component: Workspace,
		children: [
			{
				path: '/',
				name: 'fluxogram',
				component: FluxogramView
			},
			{
				path: 'interaction',
				name: 'interaction',
				component: InteractionView
			}
		],
		meta: { requiresAuth: true },
		beforeEnter: (to, from, next) => {

			const { projectId } = to.params

			const isValidProjectId = store.getters['dashboardStore/allProjects'].find((project) => project.code === projectId)


			if (isValidProjectId) {
				const currentProject = store.getters['dashboardStore/currentProject']

				// Prevents loading the page with invalid or no projectCode
				if (currentProject.code !== to.projectId) {
					store.dispatch('dashboardStore/mergeUpdateCurrentProject', { projectId: projectId })
				}

				return next()
			}

			return router.push({ path: '/' })
		}
	},

	{
		path: '/support',
		component: Support,
		children: [
			/** @todo Add guard if token is not passed to this route */
			{
				path: 'recovery-password',
				name: 'recovery-password',
				component: RecoveryPassword,
				meta: {}
			}
		]
	},

	/** Guest routes: Dont required authentication session, but some are token authenticated */
	{
		path: '/invite/confirmation',
		name: 'InviteConfirmation',
		component: AccountInviteConfirmation
	},
	{
		path: '/invite/registration',
		name: 'InviteRegistration',
		component: AccountInviteRegistration,
		props: true,
		beforeEnter: (to, from, next) => {

			if ('invitation_token' in to.query && 'role_id' in to.query && 'pid' in to.query && 'mail' in to.query) {
				return next()
			}
		}
	},

	/** player intercept api */
	{
		path: '/player/:videoId/',
		name: 'Action Player: Player',
		component: ActionPlayer,
		props: true,
		meta: { prefetch: true, isPlayer: true }
	},
	{
		path: '/player/:videoId/preview',
		name: 'Action Player: Preview',
		component: ActionPlayer,
		props: true,
		meta: { requiresAuth: false, prefetch: true, isPlayer: true }
	}
]
